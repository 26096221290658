import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Landing from './pages/Landing';
import Dashboard from './pages/Dashboard';
import Report from './pages/Report';

function Routes(){

    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={Landing} />
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/report" component={Report} />
            </Switch>
        </BrowserRouter>
    );
}

export default Routes;

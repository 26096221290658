import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import React, { useEffect, useState } from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import Sidebar from '../components/Sidebar';
import mapMarkerImg from "../images/map-marker.svg";
import api from '../services/api';
import '../styles/pages/dashboard.css';


const mapIcon = L.icon({
    iconUrl: mapMarkerImg,  
    iconSize: [58, 68],
    iconAnchor: [29, 68],
    popupAnchor: [170, 2]
  });

interface Contact {
  name: string;
  email: string;
  message: string;
  latitude: number;
  longitude: number;
}

function Dashboard() {

  const [contacts, setContacts] = useState<Contact[]>([]);

    useEffect(() =>{
        api.get('contact').then(response => {
          console.log(response);
          setContacts(response.data);
        });
    }, []);

    return (
        <div id="page-map">
          <Sidebar />

          <MapContainer center={[-22.877548,-43.2440564]} zoom={15} style={{ width: '100%', height: '100%' }}>
              <TileLayer url="https://a.tile.openstreetmap.org/{z}/{x}/{y}.png" />

              {contacts.map((contact, index) => {

                return (
                  <Marker 
                    position={[contact.latitude, contact.longitude]}
                    icon={mapIcon}
                    key={index}
                  >
                  
                  </Marker>
                );
              })}
          </MapContainer>
      </div>
    );
}

export default Dashboard;
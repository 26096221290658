import React from 'react';
import { FiArrowRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import '../styles/pages/landing.css'
import logoImg from '../images/logo.svg';

function Landing() {
    return (
        <div id="page-landing">
        <div className="content-wrapper">
          <img src={logoImg} alt="Fiolibras"></img>
  
          <h1>
            Acessar
          </h1>
  
          <Link to="/dashboard" className="enter-app">
            <FiArrowRight size={26} color="rgba(255, 255, 255, 0.6)" />
          </Link>
        </div>
      </div>
    );
}

export default Landing;